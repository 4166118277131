import actions from '../redux/game/actions';
import actionsModals from '../redux/modals/actions';
import ControllerSounds from '../controllers/controllerSounds';
import { store } from '../index';
import eModalType from '../controllers/popups/eModalType';
import eSoundsType from '../preloaders/sounds/eSoundsType';
import gameSelectors from '../redux/game/selectors';

const initManagerParams = {
  developmentGetSessionPath: 'https://int.dev.onlyplay.net/integration/request_frame/?game=luckytanks&no_redirect&no_op_launcher',
  baseData: {
    ver: '1.1'
  }
};

export const requestConfig = async () => {
  window.OPWrapperService.serverManager.initManagerGameRequests(initManagerParams);
  await window.OPWrapperService.serverManager.sendGameInitRequest();
  const response = await sendRequest({ }, 'config');

  if (response.is_game && response.bet_amount && !response.bets_available.includes(response.bet_amount)) {
    window.OPWrapperService.showMessage('Error', 'Not valid bet', true)
  }
  store.dispatch(actions.setData(response));
};

export const requestNewGame = async (betIndex) => {
  const data = {
    betIndex,
  };

  const response = await sendRequest(data, 'new_game');
  if (!response) return;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  store.dispatch(actions.setNewGame(response));
};

export const requestFieldOpen = async (index) => {
  const data = {
    index,
  };

  const response = await sendRequest(data, 'open');
  if (!response) return;

  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  if (response.is_win) {
    store.dispatch(actions.setDataFieldOpenWin(response));
    if (response.steps === 4) {
      requestTakeGame(store.dispatch);
    } else {
    }
  } else {
    window.OPWrapperService.realityCheck.blockedRealityCheck = false;
    store.dispatch(actions.setDataGameEnd(response));
  }
};

export const requestTakeGame = async () => {
  const response = await sendRequest({}, 'collect');
  if (!response) return;
  window.OPWrapperService.freeBetsController.setData(response.freebets);
  window.OPWrapperService.freeBetsController.show();
  store.dispatch(actions.setDataGameEnd(response));
  store.dispatch(actions.openOtherCells({ isWin: true }));
  OPWrapperService.ControllerStatistic.win = gameSelectors.getLastWin(store.getState()).win_amount;
  window.OPWrapperService.realityCheck.blockedRealityCheck = false;

  ControllerSounds.playSound(eSoundsType.EST_WIN)
  if (response.steps === 4) {
    store.dispatch(actionsModals.showModal({ type: eModalType.EMT_WIN_BIG }));
  } else {
    window.OPWrapperService.freeBetsController.updateTotalWin();
  }
};

let waitingForResponse = false;
const sendRequest = async (data, id, urlPath = 'game_request') => {
  if (waitingForResponse) {
    console.warn('Already waiting for response.');
    return;
  }
  data.id = id;
  waitingForResponse = true;
  const response = await window.OPWrapperService.serverManager.sendGameRequest(data, urlPath);
  waitingForResponse = false;
  return response;
};
